import floatingBottleFrames from '../images/animation/*.webp';
import classicBottleFrames from '../images/bottle-klassik/*.webp';
import negroniBottleFrames from '../images/bottle-negroni/*.webp';
import xmasBottleFrames from '../images/bottle-xmas/*.webp';
import mediterranBottleFrames from '../images/bottle-mediterran/*.webp';

let targetScale = 1;

function init() {
    /* floating bottle animation */
    let handleBottleAnimation = false;
    const ar = window.innerWidth / window.innerHeight;
    const bottle = document.body.querySelector('.floating-bottle');
    const bottleImage = bottle ? bottle.querySelector('img') : null;
    const floatingTexts = bottle ? bottle.querySelectorAll('.floating-bottle__text') : [];
    const floatingTextShow = Array.prototype.map.call(floatingTexts, ft => {
        return ((ar > 1.5 ? null : ft.getAttribute('data-show-mobile')) ?? ft.getAttribute('data-show')).split(',').map(parseFloat);
    });
    console.log(floatingTextShow);
    const selectionTitle = bottle ? bottle.nextElementSibling.querySelector('.section__title__wrapper') : null;
    if(selectionTitle) selectionTitle.classList.add('section__title__wrapper--hide');

    const selectionImage = document.body.querySelector('.selection > :first-of-type img');
    let floatingAnimationDone = false;
    const floatingAnimation = bottle ? new ImageAnimation(
        bottle.querySelector('img'),
        Object.values(floatingBottleFrames),
    ) : null;

    if(bottle && selectionImage) {
        const bottleObserver = new IntersectionObserver((entries) => {
            for(let entry of entries) {
                handleBottleAnimation = entry.isIntersecting;
            }
        });
        bottleObserver.observe(bottle);
        window.addEventListener('scroll', () => {
            if(handleBottleAnimation) {
                updateBottleAnimation();
            }
        });
        window.addEventListener('resize', () => {
            calculateAnimationSizes();
        });
        bottleImage.addEventListener('load', () => {
            calculateAnimationSizes();
        }, {
            once: true
        });
        document.fonts.ready.then(() => {
            calculateAnimationSizes();
        });

        calculateAnimationSizes();
    }

    function calculateAnimationSizes() {
        const ih = selectionImage.clientHeight;
        const h = ih + selectionImage.closest('.content').querySelector('.section__title__wrapper').clientHeight;
        selectionImage.closest('.content').style.marginTop = `-${h}px`;
        
        const bw = bottleImage.clientWidth;
        const bh = bottleImage.clientHeight;
        const bottleHeight = bw > bh ? bh : bw;

        targetScale = ih / bottleHeight;
        
        updateBottleAnimation();
    }

    function updateBottleAnimation() {
        const rect = bottle.getBoundingClientRect();
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        const v = Math.min(1, Math.max(0, rect.top < 0 ? Math.abs(rect.top) / (rect.height - viewportHeight * .9) : 0));
        floatingAnimation.scrub(v);
        
        for(let fti in floatingTextShow) {
            const show = floatingTextShow[fti];
            if(show[0] <= v && v <= show[1]) {
                floatingTexts[fti].classList.add('floating-bottle__text--show');
            } else {
                floatingTexts[fti].classList.remove('floating-bottle__text--show');
            }
        }

        if(v > .9) {
            selectionTitle.classList.remove('section__title__wrapper--hide');
        }

        const s = v > .8 ? 1 - (1 - targetScale) * (v - .8) / .2 : 1;
        const targetShift = viewportWidth * .9;
        const ty = viewportHeight / viewportWidth > 1.5 && v > .8 ? 1 - (1 - targetShift) * (v - .8) / .2 : 0;
        bottleImage.style.transform = `scale(${s}) translateY(${ty / (viewportHeight * .9) * 100}%)`;
        bottleImage.style.opacity = v >= 1 ? 0 : 1;
        selectionImage.style.opacity = v < 1 ? 0 : 1;
        floatingAnimationDone = v >= 1;
    }

    /* hover bottle animation */
    const items = document.body.querySelectorAll('.selection__item');
    for(let item of items) {
        const type = item.getAttribute("data-bottle");

        const anim = new ImageAnimation(
            item.querySelector('img'),
            Object.values(type == "negroni" 
                ? negroniBottleFrames 
                : type == "seasonal" 
                    ? xmasBottleFrames 
                    : type == "mediterran" 
                        ? mediterranBottleFrames
                        : classicBottleFrames)
        );

        item.addEventListener('mouseenter', () => {
            if(floatingAnimationDone) {
                anim.start();
            }
        });
        item.addEventListener('mouseleave', () => {
            anim.reverse();
        });
    }

    /* order form */
    const orderForm = document.querySelector('#order-form');
    if (orderForm) {
        const error = orderForm.querySelector('.form__error');
        const success = orderForm.querySelector('.form__success');
        orderForm.addEventListener('submit', e => {
            e.preventDefault()
            
            if(!orderForm.checkValidity()) {
                error.classList.add('form__error--show');
                error.innerHTML = "Stellen Sie bitte sicher, dass Sie alle Pflichtfelder korrekt ausgefüllt haben!";
                return;
            }
    
            success.classList.remove('form__success--show');
            error.classList.remove('form__error--show');
    
            fetch('./order_form.php', {
                method: 'POST',
                body: new FormData(orderForm)
            })
            .then(result => result.json())
            .then((result) => {
                if(result.success) {
                    success.classList.add('form__success--show');
                    orderForm.reset();
                    window.hcaptcha.reset();
                } else {
                    error.classList.add('form__error--show');
                    error.innerHTML = result.msg;
                }
            })
        });
    }
    
    /* parallax */
    const parallaxWrappers = document.body.querySelectorAll('.parallax');
    const parallaxLayers = document.body.querySelectorAll('.parallax__layer');
    const parallaxObserver = new IntersectionObserver((entries) => {
        for(let entry of entries) {
            entry.target.setAttribute("data-visible", entry.isIntersecting);
        }
    });
    for(let layer of parallaxLayers) {
        parallaxObserver.observe(layer);
    }
    function updateParallax() {
        if(window.innerWidth > 767) {
            for(let wrapper of parallaxWrappers) {
                const b = wrapper.getBoundingClientRect();
                const d = b.top + b.height * .5;
                
                for(let layer of wrapper.querySelectorAll('.parallax__layer')){
                    if(layer.getAttribute("data-visible") == "true") {
                        const f = parseFloat(layer.getAttribute("data-factor") ?? "0");
                        const s = parseFloat(layer.getAttribute("data-shift") ?? "0");
                        layer.style.transform = `translateX(${d * s}px) translateY(${d * f}px)`;
                    }
                }
            }
        }

        requestAnimationFrame(updateParallax);
    }
    updateParallax();

    /* toggle main menu */
    let mainNavOpen = false;
    const toggle = document.querySelector('.main-nav__toggle');
    const menu = document.querySelector('.main-nav');
    toggle.addEventListener('click', () => {
        mainNavOpen = !mainNavOpen;
        toggle.classList.toggle('main-nav__toggle--open');
        menu.classList.toggle('main-nav--open');
    });

    for(let link of menu.querySelectorAll("a")) {
        link.addEventListener('click', () => {
            mainNavOpen = false;
            toggle.classList.remove('main-nav__toggle--open');
            menu.classList.remove('main-nav--open');
        })
    }

    /* lightbox */
    const lightboxToggles = document.body.querySelectorAll('.drinks__link');
    for(let lbt of lightboxToggles) {
        lbt.addEventListener('click', ev => {
            ev.stopPropagation();
            ev.preventDefault();
            document.querySelector(`#${lbt.href.split('#')[1]}`).classList.add('drinks__modal--show');
        });
    }

    const lightboxes = document.body.querySelectorAll('.drinks__modal');
    for(let lb of lightboxes) {
        lb.querySelector('.drinks__modal__content').addEventListener('click', ev => {
            ev.stopPropagation();
        });
        lb.addEventListener('click', () => {
            lb.classList.remove('drinks__modal--show');
        });
        lb.querySelector('.drinks__modal__close').addEventListener('click', () => {
            lb.classList.remove('drinks__modal--show');
        });
    }
}

addEventListener("DOMContentLoaded", init);

class ImageAnimation {    
    constructor(
        imageElement,
        frames,
    ) {
        this._imageLoaded = true;
        this._imageElement = imageElement;
        this._frames = frames;
        this._currentFrame = 0;
        this._currentTime = 0;
        this._fps = 25;
        this._direction = 1;

        imageElement.addEventListener('load', () => {
            this._imageLoaded = true;
            if(this._pendingFrame) {
                this.showFrame(this._pendingFrame);
            }
        });
    }

    scrub(to) {
        this.showFrame(Math.round(to * (this._frames.length - 1)));
    }

    showFrame(frameNumber) {
        this._currentFrame = frameNumber;
        this._pendingFrame = null;
        if(this._imageLoaded) {
            this._imageLoaded = false;
            this._imageElement.src = this._frames[frameNumber];
        } else {
            this._pendingFrame = frameNumber;
        }
    }

    start() {
        this._lastTick = performance.now();
        this._direction = 1;
        this._tick(this._lastTick);
    }

    reverse() {
        this._lastTick = performance.now();
        this._direction = -1;
        this._tick(this._lastTick);
    }

    _tick(time) {
        const dt = time - this._lastTick;
        this._currentTime += dt * this._direction;

        const nextFrame = Math.max(0, Math.min(this._frames.length - 1, Math.round(this._currentTime / 1000 * this._fps)));

        this.showFrame(nextFrame);

        const cont = this._direction > 0 
            ? nextFrame < this._frames.length - 1 
            : nextFrame > 0;

        if(cont) {
            window.requestAnimationFrame((time) => {
                this._tick(time);
                this._lastTick = time;
            });
        } else {
            this._currentTime = this._direction > 0 
                ? 1000 * this._frames.length / this._fps
                : 0;
        }
    }
}