const _temp0 = require("../images/bottle-mediterran/SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0230.webp");
const _temp1 = require("../images/bottle-mediterran/SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0231.webp");
const _temp2 = require("../images/bottle-mediterran/SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0232.webp");
const _temp3 = require("../images/bottle-mediterran/SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0233.webp");
const _temp4 = require("../images/bottle-mediterran/SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0234.webp");
const _temp5 = require("../images/bottle-mediterran/SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0235.webp");
const _temp6 = require("../images/bottle-mediterran/SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0236.webp");
const _temp7 = require("../images/bottle-mediterran/SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0237.webp");
const _temp8 = require("../images/bottle-mediterran/SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0238.webp");
const _temp9 = require("../images/bottle-mediterran/SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0239.webp");
const _temp10 = require("../images/bottle-mediterran/SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0240.webp");
const _temp11 = require("../images/bottle-mediterran/SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0241.webp");
const _temp12 = require("../images/bottle-mediterran/SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0242.webp");
const _temp13 = require("../images/bottle-mediterran/SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0243.webp");
const _temp14 = require("../images/bottle-mediterran/SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0244.webp");
const _temp15 = require("../images/bottle-mediterran/SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0245.webp");
const _temp16 = require("../images/bottle-mediterran/SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0246.webp");
const _temp17 = require("../images/bottle-mediterran/SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0247.webp");
const _temp18 = require("../images/bottle-mediterran/SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0248.webp");
const _temp19 = require("../images/bottle-mediterran/SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0249.webp");
const _temp20 = require("../images/bottle-mediterran/SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0250.webp");
const _temp21 = require("../images/bottle-mediterran/SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0251.webp");
const _temp22 = require("../images/bottle-mediterran/SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0252.webp");
const _temp23 = require("../images/bottle-mediterran/SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0253.webp");
const _temp24 = require("../images/bottle-mediterran/SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0254.webp");
const _temp25 = require("../images/bottle-mediterran/SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0255.webp");
module.exports = {
  "SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0230": _temp0,
  "SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0231": _temp1,
  "SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0232": _temp2,
  "SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0233": _temp3,
  "SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0234": _temp4,
  "SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0235": _temp5,
  "SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0236": _temp6,
  "SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0237": _temp7,
  "SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0238": _temp8,
  "SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0239": _temp9,
  "SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0240": _temp10,
  "SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0241": _temp11,
  "SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0242": _temp12,
  "SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0243": _temp13,
  "SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0244": _temp14,
  "SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0245": _temp15,
  "SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0246": _temp16,
  "SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0247": _temp17,
  "SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0248": _temp18,
  "SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0249": _temp19,
  "SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0250": _temp20,
  "SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0251": _temp21,
  "SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0252": _temp22,
  "SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0253": _temp23,
  "SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0254": _temp24,
  "SCHI_24006_SchieferGin_Website_Packshot_Mediterran_0255": _temp25
}